<template>
  <HeartysanCategoryCreationSection
    :HC="HC"
    @save="save"
  />
</template>

<script>
  import HeartysanCategoryCreationSection from "./HeartysanCategoryCreationSection/HeartysanCategoryCreationSection";
  import {HeartysanCategory} from "../../models/HeartysanCategory";
  import {heartysanCategoryMixin} from "@/mixins/heartysanMixins/heartysanCategoryMixin";

  export default {
    name: "HeartysanCategoryCreation",
    components: {
      HeartysanCategoryCreationSection,
    },

    mixins: [heartysanCategoryMixin],


    data() {
      return {
        HC: new HeartysanCategory(),
      }
    },

    created() {
      // this.$store.dispatch('getHeartysanCategoryCreate').then(response => {
      //
      //   let respData = this.getRespData(response)
      //
      //   console.log(123, response, this.expenseTypes, this.banks, this.payees);
      // })
    },

    methods: {

    }

  }
</script>

<style scoped>

</style>
