import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function HeartysanCategory() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',
    parent: null,
    nameUa: null,
    nameEn: null,
    nameRu: null,
  }


  this.validation = {
    id: false,
    name: false,
    parent: false,
    nameUa: false,
    nameEn: false,
    nameRu: false,
  }

  this.validationTranslate = {
    id: '',
    name: '',
    parent: '',
    nameUa: '',
    nameEn: '',
    nameRu: '',
  }

  this.validationTxt = {
    id: false,
    name: false,
    parent: false,
    nameUa: false,
    nameEn: false,
    nameRu: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }
  this.getParent = () => {
    return this.data.parent
  }
  this.getNameUa = () => {
    return this.data.nameUa
  }
  this.getNameEn = () => {
    return this.data.nameEn
  }
  this.getNameRu = () => {
    return this.data.nameRu
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setParent = (val) => {
    this.data.parent = val
  }
  this.setNameUa = (val) => {
    this.data.nameUa = val
  }
  this.setNameEn = (val) => {
    this.data.nameEn = val
  }
  this.setNameRu = (val) => {
    this.data.nameRu = val
  }
}

/**
 * Global Setters
 */

HeartysanCategory.prototype.setItem = function(val) {
  this.setId(val.id)
  this.setName(val.name)
  this.setParent(val.parent_category)
  this.setNameUa(val.translationStorage.ua.name)
  this.setNameEn(val.translationStorage.en.name)
  this.setNameRu(val.translationStorage.ru.name)
}


/**
 * Prepare Data
 */

HeartysanCategory.prototype.prepareSave = function(lang) {
  let data = {
    'name': this.getNameEn(),
    "translations":{},
  }
  if (this.getParent()?.id){
    data['parent_id'] = this.getParent().id
  }

  lang.map(item => {
    switch (item.locale) {
      case 'en':
        data["translations"][item.id] = {
          name: this.getNameEn(),
        }
        break
      case 'ua':
        data["translations"][item.id] = {
          name: this.getNameUa(),
        }
        break
      case 'ru':
        data["translations"][item.id] = {
          name: this.getNameRu(),
        }
        break
    }
  })

  return data
}


/**
 * Validations
 */

HeartysanCategory.prototype.firstValidation = function() {

  let validationItems = {
    // name: this.getNameEn(),
    // parent: this.getParent()?.id,
    nameUa: this.getNameUa(),
    nameEn: this.getNameEn(),
    nameRu: this.getNameRu(),
  }

  let validationOptions = {
    // name: {type: ['empty']},
    // parent: {type: ['empty']},
    nameUa: {type: ['empty']},
    nameEn: {type: ['empty']},
    nameRu: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}
