export const heartysanCategoryMixin = {

  methods: {

    save(edit = false) {
      if(!this.HC.firstValidation()) return

      let saveType = 'createHeartysanCategory'

      let data = this.HC.prepareSave(this.$store.getters.GET_LANGUAGES)

      if (edit) {
        saveType = 'updateHeartysanCategory'
        data = {
          data: data,
          id: this.HC.getId()
        }
      }

      this.$store.dispatch(saveType, data).then((response) => {
        if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$router.push(this.$store.getters.GET_PATHS.heartysanCategory)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


  }
}
