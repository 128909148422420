<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['expenses_HeartysanCategoryDetails'])"></div>
      {{$t('heartysan_HeartysanCategoryDetails.localization_value.value')}}
    </div>


    <div class="order-create__product custom-row">
      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_HeartysanCategoryParent',])"></div>
        <DefaultSelect
            :options="parentCategories"
            :optionsLabel="'name'"
            :label="$t('heartysan_HeartysanCategoryParent.localization_value.value')"
            v-bind:class="{'ui-no-valid': HC.validation.parent}"
            :errorTxt="$t(`${HC.validationTranslate.parent}.localization_value.value`)"
            :error="HC.validation.parent"
            :otherValue="'currentTranslation'"
            @change="(val) => {HC.data.parent = val}"
            :selected="HC.data.parent"
        />
      </div>
    </div>

    <div class="order-create__product custom-row">
      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_HeartysanCategoryNameUa',])"></div>
        <DefaultInput
            v-bind:class="{'ui-no-valid': HC.validation.nameUa}"
            :error="HC.validation.nameUa"
            :errorTxt="$t(`${HC.validationTranslate.nameUa}.localization_value.value`)"
            :label="$t('heartysan_HeartysanCategoryNameUa.localization_value.value')"
            :type="'text'"
            v-model="HC.data.nameUa"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_HeartysanCategoryNameEn',])"></div>
        <DefaultInput
            v-bind:class="{'ui-no-valid': HC.validation.nameEn}"
            :error="HC.validation.nameEn"
            :errorTxt="$t(`${HC.validationTranslate.nameEn}.localization_value.value`)"
            :label="$t('heartysan_HeartysanCategoryNameEn.localization_value.value')"
            :type="'text'"
            v-model="HC.data.nameEn"
        />
      </div>
    </div>
    <div class="order-create__product custom-row">
      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_HeartysanCategoryNameRu', ])"></div>
        <DefaultInput
            v-bind:class="{'ui-no-valid': HC.validation.nameRu}"
            :error="HC.validation.nameRu"
            :errorTxt="$t(`${HC.validationTranslate.nameRu}.localization_value.value`)"
            :label="$t('heartysan_HeartysanCategoryNameRu.localization_value.value')"
            :type="'text'"
            v-model="HC.data.nameRu"
        />
      </div>
    </div>

  </div>
</template>

<script>
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "StepA",
    components: {
      DefaultInput,
      DefaultSelect,
    },

    mixins: [routeFilter],

    props: {
      HC: Object,
    },

    data() {
      return {
        parentCategories: [],
      }
    },

    mounted() {
      let myQuery = this.createQueryBuilderObject()
      let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
      this.$store.dispatch('fetchHeartysanCategory', url).then(response => {
        let data = this.getRespPaginateData(response)
        this.parentCategories = this._.filter(data, (item) => {
          return item.parent_id === 0
        })
      })
    },

    methods: {

    },


  }
</script>

<style scoped>

</style>
